<template>
    <div class="class-manage-resources">
        <div class="select-wrapper">
            <div class="select-left">
<!--                <el-select v-model="schoolValue" placeholder="请选择学校" @change="changeSchool" clearable>-->
<!--                    <el-option v-for="item in schoolList" :key="item.scl_id" :label="item.scl_name" :value="item.scl_id"></el-option>-->
<!--                </el-select>-->
            </div>
            <div class="select-btn">
                <el-button type="primary" @click="addSchool">创建学校管理员</el-button>
            </div>
        </div>
        <el-table :data="administratorList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}" class="table-content" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="admin_name" align="center" label="学校管理员名称"></el-table-column>
            <el-table-column prop="admin_account" align="center" label="账号"></el-table-column>
            <el-table-column prop="scl_name" align="center" label="归属学校"></el-table-column>
            <el-table-column prop="admin_ico" align="center" label="头像">
                <template slot-scope="scope">
                    <img class="avatar" :src="scope.row.admin_ico ? scope.row.admin_ico : require('../../assets/images/user_img.png')" alt="">
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="300px">
                <template slot-scope="scope">
                    <el-button size="small" @click="resetPwd(scope.row.admin_id)">重置密码</el-button>
                    <el-button size="small" type="primary" @click="editBtn(scope.row, scope.row.admin_id)">编辑</el-button>
                    <el-button size="small" type="danger" @click="deleteBtn(scope.row.admin_id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-right" :current-page.sync="adminPages.currentPageNum"
                       :page-size="adminPages.eachPageNum" :total="adminPages.total"
                       layout="prev, pager, next, jumper" @current-change="adminCurrentChange">
        </el-pagination>

        <el-dialog :title="adminOperTitle" :visible.sync="dialogAddAdmin" center width="30%" @close="resetForm()" :close-on-click-modal="false">
            <el-form :model="addAdminForm" ref="addAdminForm" :rules="adminRules" label-position="center" class="info-form">
                <el-form-item label="名称" :label-width="formLabelWidth" prop="admin_name">
                    <el-input v-model="addAdminForm.admin_name" autocomplete="off" placeholder="1-20字符"></el-input>
                </el-form-item>
                <el-form-item label="账号" :label-width="formLabelWidth" prop="admin_account">
                    <el-input v-model="addAdminForm.admin_account" autocomplete="off" placeholder="1-20字符"></el-input>
                </el-form-item>
                <el-form-item label="密码" v-if="adminOperTitle === '创建学校管理员'" :label-width="formLabelWidth" prop="admin_password">
                    <el-input v-model="addAdminForm.admin_password" placeholder="6-16字符"></el-input>
                </el-form-item>
                <el-form-item label="学校" :label-width="formLabelWidth" prop="s_id">
<!--                    @change="changeSchool"-->
                    <el-select v-model="addAdminForm.s_id" placeholder="请选择学校" clearable>
                        <el-option v-for="item in schoolList" :key="item.scl_id" :label="item.scl_name" :value="item.scl_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth" prop="image">
                    <el-button type="primary" v-model="addAdminForm.image" class="upload-btn" size="medium">
                        <span>{{addAdminForm.image ? '重新上传' : '上传头像'}}</span>
                        <input type="file" accept="image/*" title="" @change="changeFile($event)">
                    </el-button>
                    <div class="up-img-show" v-show="addAdminForm.image">
                        <img :src="addAdminForm.image" alt />
                    </div>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddAdmin = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addAdminForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                schoolValue: '',
                schoolList: [],
                administratorList: [],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                adminOperTitle: '',
                dialogAddAdmin: false,
                addAdminForm: {
                    admin_id: '',
                    admin_name: '',
                    admin_account: '',
                    admin_password: '',
                    s_id: '',
                    image: '',
                    imageFile:''
                },
                formLabelWidth: '80px',
                adminRules: {
                    admin_name: [
                        { required: true, message: '请输入学校管理员名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    admin_account: [
                        { required: true, message: '请输入账号', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    admin_password: [
                        { required: true, message: '请输入长度在 6 到 16 个字符', trigger: 'blur' },
                        { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
                    ],
                    s_id: [
                        { required: true, message: '请选择学校', trigger: 'change' }
                    ]
                },
                distinguishBtn: '',
            }
        },
        mounted() {
            this.getSchoolList();
            this.getAdminList();
        },
        methods: {
            getSchoolList() {
                this.$http.axiosGet(this.$api.schoolList, (res) => {
                    if (res.code === 200) {
                        this.schoolList = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getAdminList() {
                let params = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum,
                }
                this.$http.axiosGetBy(this.$api.showAdmin, params, (res) => {
                    if (res.code === 200) {
                        this.administratorList = res.data.data;
                        this.adminPages.total = res.data.total
                    }
                })
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getAdminList();
            },
            changeSchool() {
                this.getSchoolList();
            },
            addSchool() {
                this.dialogAddAdmin = true;
                this.adminOperTitle = '创建学校管理员';
                this.distinguishBtn = 'add';
            },
            editBtn(row, id) {
                this.dialogAddAdmin = true;
                this.adminOperTitle = '编辑学校管理员';
                this.distinguishBtn = 'edit';
                this.addAdminForm.admin_id = row.admin_id;
                this.addAdminForm.admin_name = row.admin_name;
                this.addAdminForm.admin_account = row.admin_account;
                this.addAdminForm.s_id = row.s_id;
                this.addAdminForm.image = row.admin_ico;
            },
            changeFile(event) {
                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                this.addAdminForm.imageFile = fileList;
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    url = this.result.substring(this.result.indexOf(',') + 1);
                    that.addAdminForm.image = "data:image/png;base64," + url;
                }
            },
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('admin_name', this.addAdminForm.admin_name);
                            formData.append('admin_account', this.addAdminForm.admin_account);
                            formData.append('admin_password', this.addAdminForm.admin_password);
                            formData.append('s_id', this.addAdminForm.s_id);
                            if (this.addAdminForm.imageFile) {
                                formData.append('image', this.addAdminForm.imageFile)
                            }
                            this.$http.axiosPost(this.$api.addAdmin, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.adminPages.currentPageNum = 1;
                                    this.getAdminList();
                                    this.dialogAddAdmin = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('admin_id', this.addAdminForm.admin_id);
                            formData.append('admin_name', this.addAdminForm.admin_name);
                            formData.append('admin_account', this.addAdminForm.admin_account);
                            formData.append('s_id', this.addAdminForm.s_id);
                            if (this.addAdminForm.imageFile) {
                                formData.append('image', this.addAdminForm.imageFile)
                            }
                            this.$http.axiosPost(this.$api.saveAdmin, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.dialogAddAdmin = false;
                                    this.getAdminList();
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetPwd(id) {
                this.$confirm('重置密码,默认123456', '重置密码', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosPost(this.$api.resetAdminPwd, {admin_id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消重置')
                });
            },
            deleteBtn(id) {
                this.$confirm('是否删除该学校管理员，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosPost(this.$api.delAdmin, {admin_id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getAdminList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            resetForm() {
                this.$refs.addAdminForm.resetFields();
                this.addAdminForm = {
                    admin_id: '',
                    admin_name: '',
                    admin_account: '',
                    admin_password: '',
                    s_id: '',
                    image: '',
                    imageFile:''
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .select-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .avatar {
        width: 32px;
        height: 32px;
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
    }
    .upload-btn {
        position: relative;
        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .up-img-show {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border: 1px solid #ccc;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 10px;
        overflow: hidden;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>