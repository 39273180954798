<template>
    <div class="index-container">
        <el-tabs v-model="activeName" class="index-tabs" @tab-click="handleClick">
            <el-tab-pane label="学校管理" name="school" v-if="role === 1">
                <span slot="label" class="tab-txt">学校管理</span>
                <SchoolManage></SchoolManage>
            </el-tab-pane>
            <el-tab-pane label="学校管理员管理" name="administrator" v-if="role === 1">
                <span slot="label" class="tab-txt">学校管理员管理</span>
                <AdministratorManage ref="administrator"></AdministratorManage>
            </el-tab-pane>
            <el-tab-pane label="教师管理" name="teacher" v-if="role === 2">
                <span slot="label" class="tab-txt">教师管理</span>
                <TeacherManage></TeacherManage>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import SchoolManage from 'components/educationManage/SchoolManage.vue'
    import AdministratorManage from 'components/educationManage/AdministratorManage.vue'
    import TeacherManage from 'components/educationManage/TeacherManage.vue'

    export default {
        data() {
            return {
                activeName: Number(localStorage.getItem('role')) === 1 ? 'school' : 'teacher'
            }
        },
        components: {
            SchoolManage,
            AdministratorManage,
            TeacherManage,
        },
        computed: {
            role() {
                return localStorage.getItem('role') ? Number(localStorage.getItem('role')) : 2;
            }
        },
        methods: {
            handleClick(tab, event) {
                if (tab.name === 'administrator') {
                    this.$refs.administrator.getSchoolList();
                }
            }
        }
    }
</script>

<style scoped>

</style>