<template>
    <div class="class-manage-resources">
        <div class="select-wrapper">
            <div class="select-left"></div>
            <div class="select-btn">
                <el-button type="primary" @click="addTeacher">创建教师</el-button>
            </div>
        </div>
        <el-table :data="teacherListTable" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}" class="table-content" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="t_name" align="center" label="教师名称"></el-table-column>
            <el-table-column prop="t_account" align="center" label="账号"></el-table-column>
            <el-table-column prop="t_ico" label="头像"  align="center">
                <template width="32" slot-scope="scope">
                    <img :src="scope.row.t_ico ? scope.row.t_ico:require('../../assets/images/user_img.png')" />
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="380">
                <template slot-scope="scope">
                    <el-button size="small" type="success" @click="assignCourses(scope.row)">分配课程</el-button>
                    <el-button size="small" @click="resetPassword(scope.row)">重置密码</el-button>
                    <el-button size="small" type="primary" @click="editBtn(scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="deleteBtn(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-right" :current-page.sync="teacherPages.currentPageNum"
                       :page-size="teacherPages.eachPageNum" :total="teacherPages.total"
                       layout="prev, pager, next, jumper" @current-change="teacherCurrentChange">
        </el-pagination>
        <!--添加/编辑弹窗-->
        <el-dialog :title="teacherTitleType" :visible.sync="dialogAddTeacher" center width="30%" @close="resetForm()" :close-on-click-modal="false">
            <el-form :model="addTeacherForm" ref="addTeacherForm" :rules="rules" label-position="center" class="info-form">
                <el-form-item label="名称" :label-width="formLabelWidth" prop="t_name">
                    <el-input v-model="addTeacherForm.t_name" autocomplete="off" placeholder="1-20字符"></el-input>
                </el-form-item>
                <el-form-item label="账号" :label-width="formLabelWidth" prop="t_account">
                    <el-input v-model="addTeacherForm.t_account" autocomplete="off" placeholder="1-20字符"></el-input>
                </el-form-item>
                <el-form-item v-if="teacherTitleType === '创建教师'" label="密码"  :label-width="formLabelWidth" prop="t_password">
                    <el-input v-model="addTeacherForm.t_password" autocomplete="off" placeholder="6-16字符"></el-input>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth" prop="image">
                    <template>
                        <input class="up-img" @change="teacherChangeFile" type="file" id="image" name="file" title/>
                        <el-button size="small" type="primary">{{ addTeacherForm.image ? '重新上传' : '点击上传' }}</el-button>
                        <img :src="addTeacherForm.image" v-show="addTeacherForm.image" class="up-img-show" alt />
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddTeacher = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addTeacherForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--分配课程弹窗-->
        <el-dialog title="分配课程" :visible.sync="teacherCourseDialog" center width="846px" :close-on-click-modal="false" @close="resetCourseForm()">
            <div class="assign-project-list">
                <el-scrollbar class="class-list" :native="false">
                    <el-cascader-panel
                            v-model="selectTeacherCourseList"
                            :options="courses"
                            :props="propsTeacherCourse"
                            @change="selectTeacherCourseChange"
                            clearable
                            class="class-tree">
                    </el-cascader-panel>
                </el-scrollbar>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="teacherCourseDialog = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="submitAssignTeacherCourse">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import _ from "underscore";

    export default {
        name: "TeacherManage",
        data() {
            return {
                //教师管理列表
                teacherListTable: [],
                //分页
                teacherPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                //弹窗标题
                teacherTitleType: '',
                //是否显示弹窗
                dialogAddTeacher: false,
                //学院管理员表单数据
                addTeacherForm: {
                    t_id: '',
                    t_name: '',
                    t_account: '',
                    t_password: '',
                    admin: '',
                    image: '',
                    imageFile:''
                },
                rules: {
                    t_name: [
                        { required: true, message: '请输入教师名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    t_account: [
                        { required: true, message: '请输入账号', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    t_password: [
                        { required: true, message: '请输入长度在 6 到 16 个字符', trigger: 'blur' },
                        { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
                    ]
                },
                formLabelWidth: '60px',
                //编辑/保存
                distinguishBtn: '',
                //是否显示分配课程弹窗
                teacherCourseDialog: false,
                //课程列表
                courses: [],
                propsTeacherCourse: { multiple: true, value: 'course_id', label: 'course_name'},
                //已选的课程列表
                selectTeacherCourseList: [],
                zhang_arr: [],
                teacher_course_list: [],
                cacheArr:[]
            }
        },
        created() {
            this.getTeacherList();
        },
        methods: {
            //教师列表
            getTeacherList() {
                let param = {
                    page: this.teacherPages.currentPageNum,
                    limit: this.teacherPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.showTeacher, param, (res) => {
                    if (res.code === 200) {
                        this.teacherListTable = res.data.data;
                        this.teacherPages.total = res.data.total;
                    }
                })
            },
            //分页
            teacherCurrentChange(val) {
                this.teacherPages.currentPageNum = val;
                this.getTeacherList();
            },
            //创建教师
            addTeacher() {
                this.dialogAddTeacher = !this.dialogAddTeacher;
                this.teacherTitleType = '创建教师';
                this.distinguishBtn = 'add';
            },
            //编辑
            editBtn(row) {
                this.dialogAddTeacher = !this.dialogAddTeacher;
                this.teacherTitleType = '编辑教师';
                this.distinguishBtn = 'edit';
                this.addTeacherForm.t_id = row.t_id;
                this.addTeacherForm.t_name = row.t_name;
                this.addTeacherForm.t_account = row.t_account;
                this.addTeacherForm.image = row.t_ico;
            },
            //删除
            deleteBtn(row) {
                this.$confirm('是否删除该教师，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    let formData = new FormData();
                    formData.append('t_id', row.t_id);
                    this.$http.axiosPost(this.$api.delTeacher, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getTeacherList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //重置密码
            resetPassword(row) {
                this.$confirm("重置密码,默认123456", "提示消息", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true
                }).then(() => {
                    // let formData = new FormData();
                    // formData.append('t_id', row.t_id);
                    this.$http.axiosPost(this.$api.resetPwd, {t_id: row.t_id}, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.msg);
                            } else {
                                this.$message.warning(res.msg);
                            }
                        },
                        err => {
                            console.log(err);
                        }
                    );
                }).catch(() => {
                    this.$message.info("已取消重置");
                });
            },
            //保存教师
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('t_name', this.addTeacherForm.t_name);
                            formData.append('t_account', this.addTeacherForm.t_account);
                            formData.append('t_password', this.addTeacherForm.t_password);
                            if (this.addTeacherForm.imageFile) {
                                formData.append("image", this.addTeacherForm.imageFile);
                            }
                            this.$http.axiosPost(this.$api.addTeacher, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message({
                                        type: 'success',
                                        message: res.msg,
                                        duration: 1000,
                                        onClose: () => {
                                            this.dialogAddTeacher = !this.dialogAddTeacher;
                                            this.resetForm();
                                            this.getTeacherList()
                                        }
                                    });
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: res.msg,
                                        duration: 1000
                                    });
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                        else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('t_name', this.addTeacherForm.t_name);
                            formData.append('t_account', this.addTeacherForm.t_account);
                            formData.append('t_id', this.addTeacherForm.t_id);
                            if(this.addTeacherForm.imageFile) {
                                formData.append('image', this.addTeacherForm.imageFile);
                            }
                            this.$http.axiosPost(this.$api.saveTeacher, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message({
                                        type: 'success',
                                        message: res.msg,
                                        duration: 1000,
                                        onClose: () => {
                                            this.dialogAddTeacher = !this.dialogAddTeacher;
                                            this.resetForm();
                                            this.getTeacherList();
                                        }
                                    });
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: res.msg,
                                        duration: 1000
                                    });
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //重置表单
            resetForm() {
                this.$refs.addTeacherForm.resetFields();
                this.addTeacherForm = {
                    t_name: '',
                    t_account: '',
                    t_password: '',
                    image: ''
                }
            },
            // 头像
            teacherChangeFile(e) {
                if (this.beforeAvatarUpload(e.target.files[0])) {
                    this.addTeacherForm.imageFile = e.target.files[0];
                    var fr = new FileReader();
                    fr.onloadend = (e)=> {
                        this.addTeacherForm.image = e.target.result;
                    };
                    fr.readAsDataURL(this.addTeacherForm.imageFile);
                } else {
                    document.getElementById("image").value = "";
                }

            },
            beforeAvatarUpload(file) {
                // const isJPG = file.type === "image/jpeg";
                const isLt2M = file.size / 1024 / 1024 < 2;

                // if (!isJPG) {
                //     this.$message.error("上传头像图片只能是 JPG 格式!");
                // }
                if (!isLt2M) {
                    this.$message.error("上传头像图片大小不能超过 2MB!");
                }
                return isLt2M;
            },
            //分配课程
            assignCourses(row) {
                this.teacherCourseDialog = true;
                this.addTeacherForm.t_id = row.t_id;
                this.$http.axiosGet(this.$api.admin_sclAdminCourseList, (res) => {
                    if (res.code === 200) {
                        let courseArr = JSON.parse(res.data.course_data);
                        this.cacheArr = res.data.course_data;
                        // courseArr.forEach((item,index)=>{
                        //     this.cacheArr.push(item[1])
                        // })
                        // if (this.selectTeacherCourseList.length === 0) {
                        //     if (courseArr.length > 0) {
                        //         this.selectTeacherCourseList = courseArr;
                        //     }
                        // }
                        this.$http.axiosPost(this.$api.teacher_editTeacher, {t_id: row.t_id}, (res) => {
                            if (res.code === 200) {
                                if (res.data.course_data) {
                                    let courseTpm = JSON.parse(res.data.course_data);
                                    let selectArr = [];
                                    for (let i = 0; i < courseTpm.length; i++) {
                                        let [cId, zhangIndex] = [courseTpm[i].course_id, courseTpm[i].zhang_arr];
                                        selectArr.push([cId, zhangIndex])
                                    }
                                    if(courseArr.length>0){
                                        this.selectTeacherCourseList =selectArr   
                                    }
                                    
                                }
                            }
                        }, (err) => {
                            console.log(err);
                        })
                    }
                    this.getTeacherLessons();
                }, (err) => {
                    console.log(err);
                })
            },
            //获取学校管理员分配的课程
            getTeacherLessons() {
                console.log('this.selectTeacherCourseList',this.selectTeacherCourseList)
                let params = {
                    parent_id: 0,
                    type: 1,
                    sort: 1
                }
                this.$http.axiosGetBy(this.$api.admin_courseLists, params, (res) => {
                    if (res.code === 200) {
                        let tmpObj = {};
                        let childrenObj = {};
                        if (this.courses.length === 0) {
                            for (let i = 0; i< res.list.length; i ++) {
                                let childrenArr = res.list[i].course_data.course_content;
                                tmpObj = {
                                    course_id: res.list[i].course_id,
                                    course_name: res.list[i].course_name,
                                    course_type: res.list[i].course_type,
                                    // children: res.list[i].course_data.course_content
                                    children: []
                                }
                                for (let j = 0; j < childrenArr.length; j++) {
                                    childrenObj = {
                                        course_name: `第 ${j+1} 章 ${childrenArr[j].name}`,
                                        course_id: j
                                    }
                                    tmpObj.children.push(childrenObj)
                                    // for (let k = 0; k < this.selectTeacherCourseList.length; k++) {
                                    //     if (tmpObj.course_id === this.selectTeacherCourseList[k][0] && childrenObj.course_id === this.selectTeacherCourseList[k][1]) {
                                    //         tmpObj.children.push(childrenObj)
                                    //     }
                                    // }
                                }
                                this.courses.push(tmpObj);
                                this.courses.forEach((item, index)=> {
                                    item.children.forEach((subItem, subIndex) => {
                                        subItem.disabled = !this.cacheArr.includes(`[${item.course_id},${subItem.course_id}]`);
                                    })
                                })
                            }
                        }
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            //选择的课程
            selectTeacherCourseChange(val) {
                this.selectTeacherCourseList = val;
            },
            //提交分配课程
            submitAssignTeacherCourse() {
                let selectCourseId = []
                let resultArr = []
                for (let i = 0; i < this.selectTeacherCourseList.length; i++) {
                    resultArr.push(this.selectTeacherCourseList[i][0]);
                    selectCourseId = resultArr.filter( function  (item, index, self) {
                        return  self.indexOf(item) === index;
                    });
                }

                let courseListArr = []
                let courseObj = {}
                for (let i = 0; i < this.selectTeacherCourseList.length; i++) {
                    courseObj = {
                        course_id: this.selectTeacherCourseList[i][0],
                        zhang_arr: this.selectTeacherCourseList[i][1]
                    }
                    courseListArr.push(courseObj);
                }

                let params = {
                    t_id: this.addTeacherForm.t_id,
                    course_ids: selectCourseId.join(','),
                    course_data: JSON.stringify(courseListArr)
                }
                this.$http.axiosPost(this.$api.school_allocationCourseToTea, params, (res) => {
                    if (res.code === 200) {
                        this.teacherCourseDialog = false;
                        this.$message({
                            type: 'success',
                            message: '分配成功',
                            duration: 1000
                        });
                        this.getTeacherList();
                    } else {
                        this.$message({
                            type: 'warning',
                            message: res.msg,
                            duration: 1000
                        });
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //重置
            resetCourseForm() {
                this.selectTeacherCourseList = [];
            }
        }
    }
</script>

<style scoped lang="scss">
    .select-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .up-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 32px;
        opacity: 0;
    }
    .up-img-show{
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #eeeeee;
        vertical-align: middle;
        margin-left: 10px;
    }
    img{
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    ::v-deep .el-form-item__label {
        text-align: center;
    }

    .assign-project-list {
        display: flex;
        height: 450px;
        max-height: 450px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        .class-list {
            height: 100%;
            width: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    height: 100%;
                    /*.el-cascader-menu {*/
                        /*&:nth-child(2) {*/
                            /*::v-deep .el-cascader-node {*/
                                /*display: none;*/
                                /*&.is-active {*/
                                    /*display: block;*/
                                /*}*/
                            /*}*/
                        /*}*/
                    /*}*/
                }
            }
            .class-tree {
                /*width: 100%;*/
                padding: 15px;
                border: none;
                height: 100%;
                box-sizing: border-box;
                ::v-deep .el-cascader-menu__wrap {
                    height: 100%;
                }
            }
        }
    }
</style>