<template>
    <div>
        <div class="qy-dialog-body" >
            <div class="qy-dialog-wrap">
                <div class="qy-dialog-header">
                    <div class="header-title">
                        <span>分配课程</span>
                    </div>
                    <div class="header-act">
                        <i class="el-icon-close" @click.stop="close"></i>
                    </div>
                </div>
                <div class="qy-dialog-main">
                    <div class="course-content">
                        <div class="course-list">
                            <div class="course-item" v-for="course in courses" @click="pushCourseIds(course.course_id)">
                                <div class="course-one" v-if="in_array(course.course_id)">
                                    <img :src="course.course_cover" :alt="course.course_name">
                                    <div class="course-modal">
                                        <div class="course-selected">
                                            <i class="el-icon-check"></i>
                                        </div>
                                        <p>{{course.course_name}}</p>
                                    </div>
                                </div>
                                <div class="course-one" v-else>
                                    <img :src="course.course_cover" :alt="course.course_name">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="course-page">
                        <el-pagination

                                layout="prev, pager, next"
                                :current-page.sync="adminPages.currentPageNum"
                                :page-size="adminPages.eachPageNum" :total="adminPages.total"
                                @current-change="changePage"
                                current-page.sync="curPage">
                        </el-pagination>
                    </div>
                </div>
                <div class="qy-dialog-footer">
                    <el-button size="small" @click.stop="close">取消</el-button>
                    <el-button size="small" type="primary" @click.stop="confirmSure">确定</el-button>
                </div>
            </div>
        </div>
        <div class="qy-dialog-modal"></div>
    </div>
</template>

<script>
    import _ from "underscore";
    export default {
        name: "CoursesDialogWrapper",
        data() {
            return {
                courses: [],
                total: 0,
                selectedIds: [],
                curPage: 1,
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 9,
                    total: 0,
                },
            }
        },
        props: {
            schoolId: {
                type: Number,
                required: true
            }
        },
        created() {
            this.getLessons();
            this.getSchoolInfo();
        },
        methods: {
            getSchoolInfo() {
                if (this.schoolId) {
                    let formData = new FormData();
                    formData.append('scl_id', this.schoolId);
                    this.$http.axiosPost(this.$api.schoolInfo, formData, (res) => {
                        console.log('11',res)
                        if (res.code === 200) {
                            if (res.data.cid_belongs) {
                                let ids = res.data.cid_belongs.split(',');
                                if (ids.length) {
                                    _.each(ids, (item) => {
                                        this.selectedIds.push(parseInt(item));
                                    });
                                }
                            }
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            getLessons() {
                let params = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum,
                    parent_id: 0,
                    type: 1,
                    sort: 1
                }
                this.$http.axiosGetBy(this.$api.courses, params, (res) => {
                    if (res.code === 200) {
                        this.courses = res.list;
                        this.adminPages.total = res.total;
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            close() {
                this.$emit('closeDialog');
            },
            changePage(val) {
                this.adminPages.currentPageNum = val;
                this.getLessons();
            },
            in_array(course_id){
                if (this.selectedIds.indexOf(course_id) !== -1) {
                    return true;
                } else {
                    return false;
                }
            },
            pushCourseIds(id){
                if (this.selectedIds.indexOf(id) === -1) {
                    this.selectedIds.push(id);
                } else {
                    this.selectedIds = _.without(this.selectedIds, id);
                }
            },
            confirmSure(){
                if (this.selectedIds.length) {
                    this.$emit('closeDialog', this.selectedIds);
                } else {
                    this.$message({
                        type: "warning",
                        message: "请选择要分配的课程",
                        duration: 1000
                    });
                }

            }


        }
    }
</script>

<style lang="scss" scoped>
    .qy-dialog-body {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        z-index: 2001;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .qy-dialog-wrap {
            box-sizing: border-box;
            background-color: #FFFFFF;
            width: 548px;
            border-radius: 2px;
            margin-top: 10%;
            padding-bottom: 10px;
            .qy-dialog-header {
                padding: 20px 20px 10px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #EAEAEA;
                .header-title {
                    font-size: 16px;
                }
                .header-act i {
                    font-size: 16px;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            .qy-dialog-footer {
                display: flex;
                justify-content: flex-end;
                height: 40px;
                padding-right: 20px;
                align-items: center;
            }
            .qy-dialog-main {
                padding: 10px 18px;
                .course-content {
                    min-height: 330px;
                    .course-list {
                        display: flex;
                        flex-wrap: wrap;
                        .course-item {
                            margin: 0 16px 20px 0;
                            .course-one {
                                position: relative;
                                border-radius: 5px;
                                overflow: hidden;
                                width: 160px;
                                height: 90px;
                                cursor: pointer;
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                                .course-modal {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background-color: rgba(0, 0, 0, 0.3);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    p {
                                        width: calc(100% - 20px);
                                        text-align: center;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        color: #FFF;
                                    }
                                    .course-selected {
                                        position: absolute;
                                        top: 10px;
                                        right: 10px;
                                        width: 20px;
                                        height: 20px;
                                        text-align: center;
                                        line-height: 20px;
                                        border-radius: 10px;
                                        background-color: #409eff;
                                        i {
                                            color: #FFF;
                                        }
                                    }
                                }
                            }
                            &:nth-child(3n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .course-page {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .qy-dialog-modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: .5;
        background: #000;
        z-index: 2000;
    }

</style>